import DropdownController from "./dropdown_controller"

// Connects to data-controller="animated-dropdown"
export default class extends DropdownController {
  static targets = ["button"]

  // connect() {
  // super.connect()
  // }

  toggle() {
    this.rotateButton()
    super.toggle()
  }

  rotateButton() {
    if (this.buttonTarget.classList.contains('rotate-0')) {
      this.buttonTarget.classList.remove('rotate-0')
      this.buttonTarget.classList.add(`rotate-${this.degreesValue}`)
    } else {
      this.buttonTarget.classList.remove(`rotate-${this.degreesValue}`)
      this.buttonTarget.classList.add('rotate-0')
    }
  }

  outsideHide(event) {
    console.log('outsideHide1', event)
    console.log('outsideHide2', event.target)
    if (event.target.closest('button[data-action="click->intum-select#toggleResult click->intum-select#removeBubble"]')) {
      return
    }
    // this.hide(event)
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains('hidden')) {
      this.rotateButton()
      this.leave()
      this.removeEvent()
    }
  }
}
