import { Controller } from "@hotwired/stimulus"

// export default class extends Controller {
//   connect () {
//     this.element.textContent = "Hello World from Stimulus1!"
//   }
// }
export default class extends Controller {
  static targets = ["fields"]

  connect() {
    console.log('connect1')
  }

  setWysiwyg() {
    this.setMarkdownOptions("wysiwyg_html")
  }

  setMarkdown() {
    this.setMarkdownOptions("github_markdown")
  }

  setAutoUpdateOff() {
    this.setMarkdownOptions("auto_update_off")
  }

  setMarkdownOptions(value) {
    const fields = this.fieldsTarget
    let fieldsAsHash = {}
    try {
      fieldsAsHash = JSON.parse(fields.value)
    } catch (e) {
      return fields.value
    }

    fieldsAsHash = this.setDefaultFields(fieldsAsHash)
    if (value) {
      fieldsAsHash.organize.task.markup_lang = value
    } else {
      delete fieldsAsHash.organize.task.markup_lang
      if (Object.keys(fieldsAsHash.organize.task).length === 0) {
        delete fieldsAsHash.organize.task
      }
      if (Object.keys(fieldsAsHash.organize).length === 0) {
        delete fieldsAsHash.organize
      }
    }

    this.fieldsTarget.value = JSON.stringify(fieldsAsHash, null, 2)
  }

  setDefaultFields(fieldsAsHash) {
    if (typeof fieldsAsHash.organize === "undefined") {
      fieldsAsHash.organize = {}
    }

    if (typeof fieldsAsHash.organize.task === "undefined") {
      fieldsAsHash.organize.task = {}
    }

    if (typeof fieldsAsHash.organize.task.markup_lang === "undefined") {
      fieldsAsHash.organize.task.markup_lang = {}
    }

    return fieldsAsHash
  }
}
