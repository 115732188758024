import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // this.element.addEventListener("click", this.saveLocation.bind(this))
  }

  showEmail(event) {
    const emailList = document.getElementById("email_list")
    const emailDetail = document.getElementById("email_detail")
    const bulkOptions = document.getElementById("mail_email_options")
    console.log('TARGEEET', event.target)
    // tu reloady i zmiana src
    // cos w stylu: enabled frame to jest jeden element ktory zawsze wiemy ze jest wlaczony
    // disabled frames to cala reszta i one wszystkie powinny byc ukryte
    if (event.target.closest('a').matches(".email-item")) {
      emailList.classList.add("hidden")
      emailDetail.classList.remove("hidden")
      bulkOptions.classList.add("hidden")
    }
  }

  hideEmail(event) {
    const emailList = document.getElementById("email_list")
    const emailDetail = document.getElementById("email_detail")
    const bulkOptions = document.getElementById("mail_email_options")
    if (event.target.closest('button').matches("#back-to-list")) {
      event.preventDefault()
      console.log('back-to-list')
      emailDetail.classList.add("hidden")
      emailList.classList.remove("hidden")
      bulkOptions.classList.remove("hidden")
    }
  }
}
