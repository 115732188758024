import { Controller } from "@hotwired/stimulus"
import { createPopper } from "@popperjs/core"

export default class extends Controller {
  static targets = ['helplink', "button"]
  lastTooltipID = null

  connect() {
    console.log("helplink connect")

    if (this.helplinkTarget.dataset.show) {
      this.openPopover({ params: { id: this.helplinkTarget.id }, target: this.buttonTarget })
    }
  }

  openPopover(event) {
    const tooltipID = event.params.id

    if (this.lastTooltipID && this.lastTooltipID !== tooltipID) {
      document.getElementById(this.lastTooltipID).classList.toggle("hidden")
      this.stopVideo(this.lastTooltipID)
    }

    const element = this.getElement(event)

    createPopper(element, document.getElementById(tooltipID), {
      placement: this.helplinkTarget.dataset.placement
    })

    const currentTooltipHidden = document.getElementById(tooltipID).classList.toggle("hidden")
    if (currentTooltipHidden === true) {
      this.lastTooltipID = null
    } else {
      this.lastTooltipID = tooltipID
      this.stopVideo(tooltipID)
    }
  }

  stopVideo(videoContainerID) {
    const containerElement = document.getElementById(videoContainerID)
    const iframeTag = containerElement.querySelector('iframe')
    if (iframeTag) {
      const iframeSrc = iframeTag.src
      iframeTag.src = iframeSrc
    }
  }

  getElement(event) {
    let element = event.target
    console.log(element.dataset.elementId)
    if (element.dataset.elementId) {
      return document.getElementById(element.dataset.elementId)
    } else {
      while (element.nodeName !== "BUTTON") {
        element = element.parentNode
      }

      return element
    }
  }
}
