import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["priority", "option", "input"]

  connect() {
    console.log('priority connect')

    // this.tabTargets[0].toggleTransition()
  }

  changePriority(event) {
    const svg = this.priorityTarget.querySelector("svg")

    // zmiana kolorów gwiazdek
    const oldColor = this.priorityTarget.dataset.currentColor
    if (oldColor) {
      svg.classList.remove(...oldColor.split(' '))
    }
    const newColor = event.params.color
    if (newColor) {
      svg.classList.add(...newColor.split(' '))
    }

    // zmiana wypełnienia/obwódki - biała i czarna gwiazdka mają obwódkę
    if ((newColor.includes("full") && !oldColor.includes("full")) || (!newColor.includes("full") && oldColor.includes("full"))) {
      const border = this.priorityTarget.querySelector(".priority-border")
      if (border.classList.contains("hidden")) {
        border.classList.remove("hidden")
      } else {
        border.classList.add("hidden")
      }
    }

    this.priorityTarget.dataset.currentColor = newColor

    this.inputTarget.value = event.params.value
  }
}
