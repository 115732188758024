import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("notification connect")

    const timeout = this.getTime()

    setTimeout(() => {
      this.element.classList.add('transform', 'opacity-0', 'transition', 'duration-1000')
    }, timeout)
    setTimeout(() => {
      this.element.remove()
    }, timeout + 1000)
  }

  getTime() {
    if (this.element.params && this.element.params.time) {
      return Number(this.element.params.time)
    } else {
      return 2000
    }
  }

  toggle() {
    this.element.remove()
  }

  disconnect() {
    this.element.remove()
  }
}
