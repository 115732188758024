import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox", "checkboxLabel"]

  connect() {
    console.log("connect1")
    this.fields.forEach(field => {
      field.classList.toggle("hidden", !this.checkboxTarget.checked)
    })
  }

  showOrHide() {
    this.fields.forEach(field => field.classList.toggle("hidden"))
  }

  initialize() {
    const element = this.element
    const childElements = element.children
    this.fields = Array.prototype.filter.call(childElements, el => el !== this.checkboxTarget && el !== this.checkboxLabelTarget)
  }
}
