import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = ["knowledgeBase", "entry", "section"]

  connect() {
    if (this.knowledgeBaseTarget.value) return

    this.knowledgeBaseChanged()
    this.entryChanged()
  }

  knowledgeBaseChanged(event) {
    const knowledgeBase = this.knowledgeBaseTarget
    const entry = this.entryTarget

    axios.get(knowledgeBase.dataset.url, {
      params: {
        id: knowledgeBase.value
      },
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => {
        console.log(response.data)

        entry.innerHTML = `<option value="" label=""></option>`

        if (!response.data) response.data = []
        response.data.forEach(entryAsPair => {
          entry.innerHTML += `<option value="${entryAsPair[0]}">${entryAsPair[1]}</option>`
        })
      })
      .catch(error => {
        console.log('error updating', error)
      })
    // this.entryTarget.innerHTML = "<option></option>"
  }

  entryChanged(event) {
    const entry = this.entryTarget
    const section = this.sectionTarget

    axios.get(entry.dataset.url, {
      params: {
        id: entry.value
      },
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => {
        console.log(response.data)
        section.innerHTML = `<option value="" label=""></option>`
        if (!response.data) response.data = []
        response.data.forEach(sectionLink => {
          section.innerHTML += `<option value="${sectionLink}">${sectionLink}</option>`
        })
      })
      .catch(error => {
        console.log('error updating', error)
      })
  }
}
