import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['preview', 'placeholder', 'filename', 'details', 'buttonRemove', 'input', 'remove']
  connect() {
    console.log('attachment connect')
  }

  showUploadResults() {
    console.log('show results')
    const input = this.inputTarget
    input.click()
    input.addEventListener('change', (event) => {
      const fileList = event.target.files
      const file = fileList[0]
      this.replaceImage(file)
    })
  }

  replaceImage(file) {
    console.log('replace')
    // Check if the file is an image.
    if (file.type && !file.type.startsWith('image/')) {
      console.log('File is not an image.', file.type, file)
      return
    }

    const reader = new FileReader()

    this.removeTarget.value = 'false'
    this.buttonRemoveTarget.classList.remove('hidden')
    console.log(file)
    this.filenameTarget.innerHTML = file.name
    this.detailsTarget.classList.remove('hidden')
    this.placeholderTarget.classList.add('hidden')
    reader.addEventListener('load', (event) => {
      this.previewTarget.src = event.target.result
    })
    reader.readAsDataURL(file)
  }

  removeFile() {
    console.log('remove')

    this.removeTarget.value = '1'
    this.inputTarget.value = ""

    this.placeholderTarget.classList.remove('hidden')
    this.buttonRemoveTarget.classList.add('hidden')
    this.filenameTarget.innerHTML = ''
    this.previewTarget.src = ''
  }
}
