// ten kontroler jest bardziej jako helper do intum_dropzone_controller
import { findElement } from "../helpers"
import { DirectUpload } from "@rails/activestorage"

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.hiddenInput = source.hiddenInputTarget
    this.file = file
    this.error = this.file.error
  }

  start() {
    this.file.controller = this
    this.directUpload.create((error, attributes) => {
      if (error) {
        this.removeElement()
        console.log('start', this.file)
        this.emitDropzoneError(error)
      } else {
        this.file.blob_signed_id = attributes.signed_id
        this.file.url = attributes.signed_url
        console.log('dodano plik, signed_id bloba to ', this.file.blob_signed_id)
        this.hiddenInput.value = this.source.dropZone.files.map(file => file.blob_signed_id)

        this.emitDropzoneSuccess()
      }
    })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    )
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100
    console.log(progress)
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = 'uploading'
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = 'error'
    console.log(this.file)
    if (this.error) {
      this.source.dropZone.emit("error", this.file, this.error)
    } else {
      this.source.dropZone.emit("error", this.file, error)
    }
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = 'success'
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }

  removeElement() {
    console.log('usunięto plik ', this.file.blob_signed_id)
    this.hiddenInput.value = this.source.dropZone.files.map(file => file.blob_signed_id)
  }
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

export function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}
