import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = ["knowledgeBase", "category"]

  connect() {
    if (!this.knowledgeBaseTarget.value) {
      this.knowledgeBaseChanged()
    }
  }

  knowledgeBaseChanged(event) {
    const knowledgeBase = this.knowledgeBaseTarget
    const category = this.categoryTarget

    axios.get(knowledgeBase.dataset.url, {
      params: {
        id: knowledgeBase.value
      },
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then(response => {
        console.log(response.data)

        category.innerHTML = `<option value="" label=""></option>`

        if (!response.data) response.data = []
        response.data.forEach(categoryAsPair => {
          category.innerHTML += `<option value="${categoryAsPair[0]}">${categoryAsPair[1]}</option>`
        })
      })
      .catch(error => {
        console.log('error updating', error)
      })
  }
}
