import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab"]

  connect() {
    console.log('cards connect')

    // this.tabTargets[0].toggleTransition()
  }

  toggleCard(event) {
    this.tabTargets.forEach(card => {
      if (card.id === event.params.id) {
        card.classList.remove('hidden')
      } else {
        card.classList.add('hidden')
      }
    })
  }
}

/*
Użycie:
  do zakładek które chcemy otwierać/zamykać należy dodać
  id="id_zakładki" data-cards-target="tab"

  a do przycisków otwierających/zamykających
  data-cards-id-param="zakładka która ma się otwierać" data-action="click->cards#toggleCard"

Działanie:
  kliknięce na przycisk otwierający jakąś zakładkę zamknie wszystkie pozostałe i zostawi otwartą tylko tę,
  na którą wskazuje data-cards-id-param z przycisku
*/
