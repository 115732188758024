export default class FieldHandler {
  constructor(element, fieldNameValue) {
    this.element = element
    this.fieldNameValue = fieldNameValue
    this.fields = this.collectHiddenFields()
    this.multiple = this.element.multiple
    console.log('FieldHandler initialized', this.fields)
  }

  handleFieldChange(value) {
    const hiddenFieldsContainer = this.element.querySelector('.hidden-fields-container')
    const fieldName = this.fieldNameValue

    if (this.hiddenFieldExists(hiddenFieldsContainer, fieldName, value)) {
      this.removeHiddenField(hiddenFieldsContainer, fieldName, value)
      console.log('Field removed')
    } else {
      this.addHiddenField(hiddenFieldsContainer, fieldName, value)
      console.log('Field added')
    }

    this.fields = this.collectHiddenFields()

    return this.fields
  }

  addHiddenField(container, fieldName, value) {
    const newField = document.createElement('input')
    newField.setAttribute('type', 'hidden')
    newField.setAttribute('name', fieldName)
    newField.value = value
    container.appendChild(newField)
  }

  removeHiddenField(container, fieldName, value) {
    let fieldToRemove
    const existingFields = container.querySelectorAll(`input[name="${fieldName}"]`)
    Array.from(existingFields).forEach(field => {
      if (field.value === value) {
        fieldToRemove = container.removeChild(field)
      }
    })

    if (existingFields.length === 1) {
      this.addHiddenField(container, fieldName, '')
    }

    return fieldToRemove
  }

  hiddenFieldExists(container, fieldName, value) {
    const existingFields = container.querySelectorAll(`input[name="${fieldName}"]`)
    return Array.from(existingFields).some(field => field.value === value)
  }

  collectHiddenFields() {
    const container = this.element.querySelector('.hidden-fields-container')
    return container.querySelectorAll('input[type="hidden"]')
  }
}
