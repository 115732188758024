import DropdownController from './dropdown_controller'

// do scalenia z animated_dropdown_controller.js i zabranie wszystkich funkcji z comments_controller.js do tego pliku
export default class extends DropdownController {
  static outlets = ["dropdown-photo", "child-dropdown"]
  static targets = ["menu", "input", "list", "childDropdown"]

  connect() {
    super.connect()
    if (this.hasChildDropdownTarget) {
      this.childDropdownTarget.classList.add('hidden')
      this.addEventListenersToLinks()
    }
    this.disableAllLinks()
  }

  toggleChild(event) {
    event.preventDefault()

    this.childDropdownTarget.classList.remove('hidden')

    const childDropdown = this.findChildElement()
    console.log('childDropdown', childDropdown)
    const mainElement = childDropdown.element
    console.log('mainElement', mainElement)

    // firstChild czyli element ktory widzimy na stronie zanim otworzymy dropdown w normalnych warunkach
    // ukrywamy go bo chcemy zeby po otwarciu dropdowna byl widoczny tylko element z dropdowna
    const firstChild = mainElement.firstElementChild.firstElementChild

    console.log('firstChild', firstChild)
    firstChild.classList.add('hidden')

    childDropdown.open()
    mainElement.querySelector('input').focus()
  }

  // znajdujemy konkretny dropdown np dropdownPhoto, ktory jest podpiety pod nasz dropdown
  findChildElement() {
    if (this.hasDropdownPhotoOutlet) {
      return this.dropdownPhotoOutlets.find(controller => {
        return controller.element.parentElement === this.childDropdownTarget
      })
    } else {
      return this.childDropdownOutlets.find(controller => {
        return controller.element.parentElement === this.childDropdownTarget
      })
    }
  }

  // wylaczam wszystkie linki w dropdownie, poniewaz nie chcemy zeby po kliknieciu w link odpalalo sie bulk_action
  // dubluja sie wtedy requesty i ida dwa naraz
  disableAllLinks() {
    const links = this.findLinksFromDropdown()
    console.log('links', links)
    links.forEach(link => {
      link.href = '#'
    })
  }

  // z racji, ze konstrukcja tego dropdownu jest taka, ze akcje sa wywolywane przez klikniecie w link
  // to musimy sluchac eventow na linkach
  findLinksFromDropdown() {
    if (this.hasChildDropdownTarget) {
      return this.childDropdownTarget.querySelectorAll('li > a')
    } else {
      return []
    }
  }

  // do kazdego linka dodajemy listener, ktory tworzy i wysyla button odpowiedzialny za tworzenie komentarza i przypisanie go do usera
  addEventListenersToLinks() {
    const links = this.findLinksFromDropdown()
    links.forEach(link => {
      link.addEventListener('click', this.submitButtonAndCloseDropdown.bind(this))
    })
  }

  submitButtonAndCloseDropdown(e) {
    const dropdownPhoto = this.findChildElement()
    dropdownPhoto.toggle()

    // czasami wylapuje diva wewnatrz <a> a czasami <a> wiec musze sprawdzic ktory to jest
    // if (e.target.dataset.value) {
    //   this.createAndSubmitNewButton(e.target.dataset.value)
    // } else {
    //   this.createAndSubmitNewButton(e.target.parentElement.dataset.value)
    // }

    // // to jest workaround, poniewaz probowalem dolaczyc drugi outlet ktory odpowiada za dropdown w ktorym otwierany jest
    // // dropdownPhoto, ale cos chyba z tym API jest jeszcze nie tak bo nie wylapuje tego kontrolera prawidlowo, wiec to jest
    // // po to zeby zamknac dropdown po tym jak komentarz zostanie zapisany
    // this.element.click()
    // this.buttonTarget.classList.remove('bg-gray-100')
  }
}
