import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js"

export default class extends Controller {
  static targets = ["canvas"]

  connect() {
    const sprintChart = this.canvasTarget

    const tailwindColors = [
      { // blue
        default: "rgba(59, 130, 246, 1)",
        half: "rgba(59, 130, 246, 0.5)",
        quarter: "rgba(59, 130, 246, 0.25)",
        zero: "rgba(59, 130, 246, 0)"
      },
      { // red
        default: "rgba(244,63,94, 0.7)",
        half: "rgba(244,63,94, 0.4)",
        quarter: "rgba(244,63,94, 0.2)",
        zero: "rgba(244,63,94, 0)"
      },
      { // gray
        default: "rgba(209, 213, 219, 0.6)",
        half: "rgba(209, 213, 219, 0.3)",
        quarter: "rgba(209, 213, 219, 0.15)",
        zero: "rgba(209, 213, 219, 0)"
      }
    ]

    const data = []
    // values powinny być podane (dla większej ilości danych na wykresie) oddzielone |
    // np.: 1,2,4,6|5,4,2,0
    sprintChart.dataset.values.split('|').forEach((line, i) => {
      const index = Math.min(tailwindColors.length - 1, i)
      data.push({
        tension: 0,
        data: line.split(','),
        fill: true,
        backgroundColor: (context) => {
          const ctx = context.chart.ctx
          const gradient = ctx.createLinearGradient(0, 0, 0, sprintChart.height / 1.2)
          gradient.addColorStop(0, tailwindColors[index].half)
          gradient.addColorStop(0.35, tailwindColors[index].quarter)
          gradient.addColorStop(1, tailwindColors[index].zero)
          return gradient
        },
        hoverBackgroundColor: tailwindColors[index].default,
        borderWidth: 2,
        borderColor: tailwindColors[index].default
      })
    })

    const labelValue = sprintChart.dataset.dates.split(',')

    this.chart = new Chart(sprintChart, {
      type: 'line',
      data: {
        labels: labelValue,
        datasets: data
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          mode: 'point'
        },
        scales: {
          xAxes: [{
            gridLines: {
              color: 'rgba(200, 200, 200, 1)',
              lineWidth: 0
            }
          }],
          yAxes: [{
            ticks: {
              precision: 0,
              beginAtZero: true,
            },
            gridLines: {
              color: 'rgba(200, 200, 200, 1)',
              lineWidth: 1
            },
          }],
          y: {
            beginAtZero: true
          }
        },
        legend: {
          display: false
        },
      }
    })
  }
}
