import { Controller } from "@hotwired/stimulus"
import axios from 'axios'
// import { useTransition } from 'stimulus-use'
// import { useClickOutside, useIntersection, useTransition } from 'stimulus-use'
// import { IntersectionController } from 'stimulus-use'

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ['title', 'description', 'modal', 'success', 'filename', 'signedId']
  attachmentOptions = ''

  connect() {
    console.log('connectmodal')
  }

  open(attachmentOptions) {
    console.log('open')
    this.modalTarget.classList.remove('hidden')
    this.successTarget.classList.add('hidden')
    this.attachmentOptions = attachmentOptions

    const id = this.attachmentOptions.attachmentId
    const filename = this.attachmentOptions.filename
    const title = this.attachmentOptions.title
    const description = this.attachmentOptions.description

    this.titleTarget.value = title
    this.titleTarget.name = `attachment_title`

    this.descriptionTarget.value = description
    this.descriptionTarget.name = `attachment_description`

    this.filenameTarget.innerHTML = filename

    this.signedIdTarget.value = id
  }

  close() {
    console.log('close')
    this.modalTarget.classList.add('hidden')
  }

  replaceAttachment(event) {
    console.log(event.target)
  }

  update(event) {
    console.log('update')
    const description = this.descriptionTarget.value
    const title = this.titleTarget.value
    console.log(this.attachmentOptions)
    const id = this.attachmentOptions.attachmentId
    const url = this.modalTarget.dataset.url
    const success = this.successTarget

    const displayTitle = document.getElementById(`display_title_${id}`)
    const displayDescription = document.getElementById(`display_description_${id}`)
    displayTitle.innerHTML = title
    displayDescription.innerHTML = description

    event.preventDefault()
    const data = {
      attachment_id: id,
      title: title,
      description: description
    }

    axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) => {
        console.log('update success')
        success.classList.toggle('hidden')
      // this.announceChangedSectionToScreenReader()
      })
      .catch((error) => {
        console.log('error updating', error)
      })
  }

  disconnect() {
    this.removeEvent()
  }
}
