// import { useTransition } from 'stimulus-use'
import axios from 'axios'
import DropdownController from "./dropdown_controller"
const debounce = require('lodash/debounce')

export default class extends DropdownController {
  static targets = ["menu", "input", "list", "searchWait", "searchResults", "help", "error", "navigation", "subContainer"]

  eventCreated = false

  connect() {
    console.log('connect1')
    super.connect()
    this.errorTarget.classList.add('hidden')
    // this.menuTarget.classList.add('hidden')

    // useTransition(this, { element: this.menuTarget })

    this.updateSearchDebounce = debounce(this.updateSearchDebounce, 500).bind(this)
  }

  updateSearchInfoShow() {
    this.errorTarget.classList.add('hidden')
    if (this.hasSearchResultsTarget) {
      this.searchResultsTarget.classList.remove('hidden')
    }
    this.helpTarget.classList.add('hidden')
    this.searchWaitTarget.classList.remove('hidden')
    this.searchWaitTarget.classList.add('block')
  }

  updateSearchInfoHide() {
    this.searchWaitTarget.classList.add('hidden')
    this.searchWaitTarget.classList.remove('block')
  }

  updateSearch(event) {
    console.log(event)
    this.updateSearchInfoShow()
    this.updateSearchDebounce(event)
  }

  updateSearchDebounce(event) {
    console.log('update')
    const input = this.inputTarget.value
    const url = this.inputTarget.dataset.url
    console.log(encodeURIComponent(input))

    event.preventDefault()

    axios.get(url, {
      params: {
        q: encodeURIComponent(input)
      },
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) => {
        console.log('update success')
        this.updateSearchInfoHide()
      // this.announceChangedSectionToScreenReader()
      })
      .catch((error) => {
        console.log('error updating', error)
        this.errorTarget.classList.remove('hidden')
        this.updateSearchInfoHide()
      })
  }
}
