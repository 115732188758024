import { jsonGreedyPost } from '../helpers'
import DropdownController from "./dropdown_controller"

export default class extends DropdownController {
  static targets = ["menu", "input", "list", "output"]

  connect() {
    console.log("greedy-dropdown connect")
    super.connect()
    this.handleJsonLinks()
  }

  handleJsonLinks() {
    const forms = this.menuTarget.querySelectorAll("form.button_to")
    for (const form of forms) {
      form.dataset.action = "submit->greedy-dropdown#submit"
    }
  }

  submit(e) {
    e.preventDefault()
    const form = e.target
    const url = form.action
    const currentOutputInnerHTML = this.outputTarget.innerHTML

    const changeTargetFn = function() {
      this.outputTarget.innerHTML = form.querySelector('button').innerHTML
    }.bind(this)
    const rollbackHandler = function() {
      console.error("Something went wrong - rolling back DOM changes")
      this.outputTarget.innerHTML = currentOutputInnerHTML
    }.bind(this)

    jsonGreedyPost(url, changeTargetFn, rollbackHandler)
  }
}
