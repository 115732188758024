import DropdownController from "./dropdown_controller"

// Connects to data-controller="country-prefix"
// zeby to poprawnie dzialalo, trzeba dodac dwa ukryte pola w formularzu phoneInput i prefixInput, ktore beda wypelniane po wybraniu prefixu i numeru
export default class extends DropdownController {
  static targets = ["input", "prefix", "numberInput", "list", "prefixInput", "phoneInput", "emoji"]

  connect() {
    super.connect()
    this.element.addEventListener('client-modal:show-form', this.showForm.bind(this))
    this.setNumber()
    this.setPrefix()
  }

  select(e) {
    const li = e.target.closest('li')
    const prefix = `+${li.dataset.prefix}`
    const pickerEmoji = li.querySelector("#emoji").innerText

    this.prefixInputTarget.setAttribute('value', prefix)
    this.prefixTarget.dataset.value = prefix
    this.emojiTarget.innerText = pickerEmoji
    this.prefixTarget.innerText = prefix
  }

  fillInputs() {
    const prefix = this.prefixTarget.dataset.value
    const number = this.numberInputTarget.value

    this.prefixInputTarget.setAttribute('value', prefix)
    this.phoneInputTarget.setAttribute('value', number)
    console.log('full', prefix, number)
  }

  setNumber() {
    const number = this.phoneInputTarget.value
    if (number && number.length > 0) {
      this.numberInputTarget.value = number
    }
  }

  setPrefix() {
    const prefix = this.prefixTarget.dataset.value
    if (prefix) {
      this.prefixInputTarget.setAttribute('value', prefix)
    }
  }

  showForm(e) {
    const number = e.detail.phone

    if (number && number.length > 0) {
      this.numberInputTarget.value = number
    }
  }
}
