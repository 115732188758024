import { humanFilesize } from '../helpers'
import { createDirectUploadController } from '../helpers/direct_upload_controller'

// interface OptionsObject {
//   maxFilesize: Number
//   acceptedFiles: string
//   maxFiles: Number
// }

export default class DropzoneObject {
  // controller: any
  // options: OptionsObject
  // files: File[]
  constructor(controller, options) {
    this.controller = controller
    this.options = options
    this.files = []
  }

  addFile(file) {
    if (!this.validateFileTypes(file) || this.validateFilesAmount()) return false

    this.validateFileSize(file)

    this.files.push(file)
    createDirectUploadController(this.controller, file).start()

    return true
  }

  addMockfile(file) {
    this.files.push(file)
    file.status = "success"
    file.controller = createDirectUploadController(this.controller, file)
    this.emit("processing", file)
    this.emit("complete", file)
  }

  removeFile(file) {
    // TODO
    // if (file.status === 'uploading') {
    //   this.cancelUpload(file)
    // }
    this.files = this.files.filter(f => f !== file)

    this.emit("removedFile", file)
    if (this.files.length === 0) {
      return this.emit("reset", file)
    }
  }

  resetFiles() {
    this.files.forEach(file => {
      this.removeFile(file)
    })
  }

  emit(event, detail, error = null) {
    detail.error = error
    const params = { bubbles: true, cancelable: true, detail: detail }
    this.controller.element.dispatchEvent(new CustomEvent(`dropzone:${event}`, params))
  }

  validateFileTypes(file) {
    const fileExtension = file.name.split('.').pop()
    if (this.options.acceptedFiles && !this.options.acceptedFiles.includes(fileExtension)) {
      this.emit("error", file, "You can't upload files of this type.")
      return false
    }
    return true
  }

  validateFilesAmount() {
    if (this.options.maxFiles && this.files.length >= this.options.maxFiles) {
      this.emit("error", {}, "You can't upload any more files.")
      return true
    }
    return false
  }

  validateFileSize(file) {
    if (this.options.maxFilesize && file.size >= this.options.maxFilesize) {
      this.emit("error", file, `Your file is too big, (${humanFilesize(file.size)}),  (max ${humanFilesize(this.options.maxFilesize)}).`)
      return true
    }
    return false
  }
}
