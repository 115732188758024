// komponent kopiujący wartości pomiędzy polami
// używany przy wypełnianiu godzin pracy w profilu usera
// (wypełniamy pn i możemy skopiować wartości do pozostałych dni tygodnia)
// jeśli w jednym kontrolerze chcemy skopiować wiele pól wtedy możemy je rozróżnić używając data-scope
// przykład w: /design/uix/stimulus#copy_value

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["from", "to"]

  copy() {
    this.fromTargets.forEach(from => {
      this.toTargets.forEach(to => {
        // console.log('1', from.getAttribute("data-scope"), '2', to.getAttribute("data-scope"))
        if (from.getAttribute("data-scope") === to.getAttribute("data-scope")) {
          to.value = from.value
        }
      })
    })
  }
}
