import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dropdown-change-multiselect-fill"
export default class extends Controller {
  static targets = ["select", "textField"];

  connect() {
    console.log("Connected to RecordController")
    // this.records = document.querySelectorAll(`#${this.element.dataset.frameId}`)
    // console.log('records', this.records)
  }

  onSelectChange(event) {
    const selectedId = event.target.value
    this.fetchRecords(selectedId)
  }

  async fetchRecords(selectedId) {
    const searchId = this.element.dataset.searchId
    const response = await fetch(`${this.element.dataset.url}?${searchId}=${selectedId}`, {
      headers: { Accept: "application/json" },
    })

    if (response.ok) {
      const ids = await response.json()
      console.log('ids', ids)
      const frame = document.querySelector(`turbo-frame#${this.element.dataset.frameId}`)
      frame.src = document.location.href + `?${frame.dataset.overrideName}=${ids.ids}`
    } else {
      console.error("Failed to fetch records")
    }
  }
}
