import AttachmentViewController from "./attachment_view_controller"

export default class extends AttachmentViewController {
  openModal(event) {
    event.preventDefault()
    console.log('openModal')
    this.hidden = false
    this.modalOpen = true
    this.toggleTransition()

    this.setImage()
    this.resizeImageToFit()
    if (this.eventCreated === false) {
      const fn = this.outsideHide.bind(this)
      this.eventCreated = fn
      console.log('eventCreated1')
      document.addEventListener('keydown', fn)
      document.addEventListener("click", fn)
    }
  }

  setImage() {
    const slide = this.slideTarget
    const image = this.imageTarget
    slide.classList.remove("hidden")
    this.currentImage = image
    this.downloadTarget.href = image.src
  }
}
