export function hasProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

export function lastItemOf(arr) {
  return arr[arr.length - 1]
}

// push only the items not included in the array
export function pushUnique(arr, ...items) {
  items.forEach((item) => {
    if (arr.includes(item)) {
      return
    }
    arr.push(item)
  })
  return arr
}

export function stringToArray(str, separator) {
  // convert empty string to an empty array
  return str ? str.split(separator) : []
}

export function isInRange(testVal, min, max) {
  const minOK = min === undefined || testVal >= min
  const maxOK = max === undefined || testVal <= max
  return minOK && maxOK
}

export function limitToRange(val, min, max) {
  if (val < min) {
    return min
  }
  if (val > max) {
    return max
  }
  return val
}

export function createTagRepeat(tagName, repeat, attributes = {}, index = 0, html = '') {
  const openTagSrc = Object.keys(attributes).reduce((src, attr) => {
    let val = attributes[attr]
    if (typeof val === 'function') {
      val = val(index)
    }
    return `${src} ${attr}="${val}"`
  }, tagName)
  html += `<${openTagSrc}></${tagName}>`

  const next = index + 1
  return next < repeat
    ? createTagRepeat(tagName, repeat, attributes, next, html)
    : html
}

// Remove the spacing surrounding tags for HTML parser not to create text nodes
// before/after elements
export function optimizeTemplateHTML(html) {
  return html.replace(/>\s+/g, '>').replace(/\s+</, '<')
}
