import { Controller } from "@hotwired/stimulus"
// import { DirectUpload } from "@rails/activestorage"
import axios from 'axios'

// Connects to data-controller="markdown-editor"
export default class extends Controller {
  static targets = ["editor", "preview", "editorButton", "previewButton", "content", "spinner", "previewContent"]
  connect() {
    this.previewTarget.classList.add('hidden')
  }

  toggleWrite() {
    this.editorTarget.classList.remove("hidden")
    this.editorButtonTarget.classList.add("bg-gray-100")
    this.editorButtonTarget.classList.remove("bg-white")
    this.editorButtonTarget.disabled = true

    this.previewTarget.classList.add("hidden")
    this.previewButtonTarget.classList.add("bg-white")
    this.previewButtonTarget.classList.remove("bg-gray-100")
    this.previewButtonTarget.disabled = false
  }

  togglePreview() {
    this.spinnerTarget.classList.remove("hidden")

    this.editorTarget.classList.add("hidden")
    this.editorButtonTarget.classList.remove("bg-gray-100")
    this.editorButtonTarget.classList.add("bg-white")
    this.editorButtonTarget.disabled = true

    this.previewTarget.classList.remove("hidden")
    this.previewButtonTarget.classList.remove("bg-white")
    this.previewButtonTarget.classList.add("bg-gray-100")
    this.previewButtonTarget.disabled = true
  }

  generatePreview() {
    console.log('preview')
    const content = this.contentTarget.value
    const url = this.previewButtonTarget.dataset.url

    const data = {
      content: content
    }

    axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) => {
        console.log('update success')
        console.log(response.data)
        this.editorButtonTarget.disabled = false
        this.previewContentTarget.innerHTML = ''
        this.previewContentTarget.insertAdjacentHTML('beforeend', response.data)
        this.spinnerTarget.classList.add("hidden")
        // success.classList.toggle('hidden');
        // this.announceChangedSectionToScreenReader();
      })
      .catch((error) => {
        console.log('error updating', error)
      })
  }
}
