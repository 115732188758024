import { useTransition } from 'stimulus-use'
import MultiselectController from "./multiselect_controller"
import axios from 'axios'

export default class extends MultiselectController {
  // inputElement: elementy dodane w inpucie(domyslnie ukryty),
  // listElement: elementy widoczne po rozwinieciu dropdownu,
  // inputElement[i].id === listElement[i].params.id
  // targets z prefixem own to elementy tworzone na podstawie inputu od usera, dzieki temu mozemy wysylac na maile spoza listy kontaktow
  static targets = ["placeholder", "list", "input", "userInput", "listElement", "inputElement", "spinner",
    "notFound", "container", "ownInputElement", "ownListElement", "ownListElementTick",
    "ownListElementText", "ownListElementX", "ownInputElementText", "ownListElementTemplate", "ownInputElementTemplate"]

  listCounter
  hiddenInputPrefix = this.getPrefix(this.menuTarget.id)
  hiddenInput = document.getElementById(this.hiddenInputPrefix)
  ownInputContext = 'own0'
  templateRefrence = 'own0'
  hideOwn = this.element.dataset.hide === 'true'
  menuHidden = false

  connect() {
    console.log('multiselect search')
    // super.connect()
    document.addEventListener(`multisearch_autocomplete_${this.hiddenInput.id}`, () => {
      this.unhideTickedIds()
    })

    this.listCounter = -1
    this.notFoundTarget.classList.add('hidden')
    this.spinnerTarget.classList.add('hidden')
    // this.menuTarget.classList.add('hidden')

    const values = this.getInputTargetsValues()

    this.startSelect(values)

    if (!this.hideOwn) {
      this.createNewOwnElement('initial')
    }

    // blokada scrollowania calej strony przy uzywanie UP/DOWN, jesli otwarty jest dropdown
    window.addEventListener('keydown', this.preventKeyboardEvents.bind(this), false)

    this.loadedValue = true

    // useTransition(this, { element: this.menuTarget })
    if (this.menuTarget.classList.contains('hidden')) {
      // this.menuTarget.classList.add('hidden')
      this.menuHidden = true
      useTransition(this, { element: this.menuTarget })
    }

    if (this.containerTarget.dataset.multiple === "false") {
      this.filterDropdown()
    }
  }

  select(event) {
    const index = event.params.id.lastIndexOf('_')
    const id = event.params.id.slice(index + 1)
    if (this.containerTarget.dataset.multiple === "false") {
      const currentValues = this.getInputTargetsValues().filter(val => val !== id)

      this.startSelect(currentValues)
    }
    const prefix = this.getPrefix(event.params.id)
    const show = document.getElementById(prefix + '_show_' + id)
    const value = show.dataset.value
    const suffix = this.getSuffix(event.params.id)
    this.userInputTarget.focus()
    this.userInputTarget.value = ''

    console.log('id', event.params.id)

    if (!this.hideOwn && suffix.includes('own')) {
      const newIncrementedId = this.incrementId(event.params.id)
      if (!document.getElementById(newIncrementedId)) {
        this.createNewOwnElement(id)
      }
    }

    this.selectHelper(prefix, id, value, show)
    this.filterDropdown()

    if (!event.delete) {
      this.element.dispatchEvent(new Event("select"))
    }
  }

  startSelect(values) {
    const menuId = this.menuTarget.id
    const prefix = this.getPrefix(menuId)
    const nodes = this.inputElementTargets
    const ids = {}

    nodes.forEach(node => {
      const value = node.dataset.value
      const index = node.id.lastIndexOf('_')
      const id = node.id.slice(index + 1)
      if (values.indexOf(value) !== -1) {
        ids[value] = id
      }
    })

    if (!this.hideOwn && ids !== undefined) {
      this.createNewOwnElement(this.ownInputContext)
    }

    values.forEach(value => {
      if (ids[value]) {
        const valueShow = document.getElementById(prefix + '_show_' + ids[value])
        this.selectHelper(prefix, ids[value], value, valueShow)
      } else if (!this.hideOwn) {
        // TODO ogarniecie wykrywania ktore elementy trzeba zaznaczyc
        // const elements = Array.from(document.querySelectorAll(`[data-value='${value}']`))
        // var elementCount = false
        // // debugger
        // elements.filter(function(element) {
        //   if (element.dataset.value === value && element.id.includes('_own')) {
        //     elementCount = true
        //   }
        // })

        // if (!elementCount) {
        console.log('value', value)
        this.userInputTarget.value = value
        const e = {
          params: {
            id: `${this.hiddenInputPrefix}_${this.ownInputContext}`
          },
          target: {
            value: value
          },
          key: 'Enter'
        }
        this.fillOwnInput(e)
        this.selectEnter(e)
        // }
      }
    })
  }

  selectHelper(prefix, id, value, show) {
    const tick = document.getElementById(prefix + '_tick_' + id)
    const x = document.getElementById(prefix + '_x_' + id)

    // pole formularza
    let values = this.getInputTargetsValues()

    if (tick.classList.contains('hidden')) {
      tick.classList.remove('hidden')
      x.classList.remove('hidden')
      show.classList.remove('hidden')
      show.classList.add('flex')
      // zabezpieczenie by nie dodawać kilka razy tego samego elementu
      if (values.indexOf(value) === -1) {
        values.push(value)
      }
    } else {
      tick.classList.add('hidden')
      x.classList.add('hidden')
      show.classList.add('hidden')
      show.classList.remove('flex')
      this.removeInputTargetValue(value)
      values = values.filter(e => e !== value)
    }

    values.forEach(value => {
      this.setInputTargetValue(value)
    })
  }

  updateSearch(event) {
    if (this.userInputTarget.value.length > 0) {
      this.selectEnter(event)
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      console.log('nie przeladuje')
      return
    }

    this.toggleInput()
    this.notFoundTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('hidden')
    this.filterDropdown(event)
    if (!this.hideOwn) {
      this.fillOwnInput(event)
    }
  }

  filterDropdown(event = undefined) {
    this.listCounter = -1
    const field = this.hiddenInput
    const input = this.userInputTarget.value
    const url = this.element.dataset.url
    const prefix = field.id
    const ids = this.getInputTargetsValues()
    const returning = this.element.dataset.returning
    //  = field.value.split(/[\s,]+/)
    const idsToTick = []
    // ids.shift()

    ids.forEach((id) => {
      const inputElement = this.inputElementTargets.find(target => id === target.dataset.value)
      if (inputElement !== undefined) {
        idsToTick.push(this.getSuffix(inputElement.id))
      }

      if (!this.hideOwn) {
        const ownElement = this.ownInputElementTargets.find(target => id === target.dataset.value)
        if (ownElement && inputElement && ownElement.dataset.value !== inputElement.dataset.value) {
          idsToTick.push(this.getSuffix(ownElement.id))
        }
      }
      // TODO ogarnac chowanie duplikatow
      // const duplicatesFound = this.ownInputElementTargets.some(target => target.dataset.value === ownElement.dataset.value &&
      //   target.id !== ownElement.id)
      // console.log('duplicate', duplicatesFound)
    })

    if (event !== undefined) {
      event.preventDefault()
    }
    console.log("query", "ids=" + ids + "&id=" + prefix + "&returning=" + returning + "&q=" + encodeURIComponent(input))
    axios.get(url, {
      params: {
        ids: ids,
        id: prefix,
        returning: returning,
        q: encodeURIComponent(input)
      },
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) => {
        this.spinnerTarget.classList.add('hidden')

        console.log("values", ids)
        // this.startSelect(ids)
        // defer
        setTimeout(() => {
          idsToTick.forEach(id => this.tickOption(prefix, id))
        }, 0)
      })
      .catch((error) => {
        this.spinnerTarget.classList.add('hidden')
        this.notFoundTarget.classList.remove('hidden')
        console.log('update failed', error)
      })
  }

  // wypelnienie "wlasnego" inputu
  fillOwnInput(event) {
    const text = event.target.value
    const contactElement = this.inputElementTargets.find(target => target.dataset.value === text)
    const targetedInputElement = this.ownInputElementTargets.find(target => this.getSuffix(target.id) === this.ownInputContext)
    const targetedListText = this.ownListElementTextTargets.find(target => this.getSuffix(target.id) === this.ownInputContext)
    const targetedListElement = this.ownListElementTargets.find(target => this.getSuffix(target.id) === this.ownInputContext)
    const targetedInputText = this.ownInputElementTextTargets.find(target => this.getSuffix(target.id) === this.ownInputContext)
    targetedInputElement.dataset.value = text
    targetedListText.innerText = text
    targetedInputText.innerText = text

    if (contactElement || text.length < 1) {
      targetedListElement.classList.add('hidden')
    } else {
      targetedListElement.classList.remove('hidden')
    }
  }

  // tworzymy nowy "wlasny" element
  createNewOwnElement(id) {
    // first element created
    const prefix = this.hiddenInputPrefix
    // const ownInputContext = this.ownInputContext
    const templateRefrence = this.templateRefrence
    const autocompleteContainer = document.querySelector(`#${prefix}-multisearch-autocomplete-container`)
    const inputsContainer = document.querySelector(`#${prefix}-multisearch-inputs-container`)
    const mainId = `${prefix}_${templateRefrence}`
    const tickId = `${prefix}_tick_${templateRefrence}`
    const optionId = `${prefix}_option_${templateRefrence}`
    const xId = `${prefix}_x_${templateRefrence}`
    const showId = `${prefix}_show_${templateRefrence}`
    const showOptionId = `${prefix}_show_option_${templateRefrence}`
    const showXId = `${prefix}_show_x_${templateRefrence}`

    if (id === 'initial') {
      this.createInitialOwnElement(autocompleteContainer, inputsContainer)
    } else {
      // const recentSuffix = this.getSuffix()
      // // return if the element is already created
      // if (document.getElementById(newMainId)) {
      //   return
      // }

      this.ownInputContext = this.incrementId(this.ownInputContext)
      const newMainId = this.replaceSuffix(mainId, this.ownInputContext)
      const newTickId = this.replaceSuffix(tickId, this.ownInputContext)
      const newOptionId = this.replaceSuffix(optionId, this.ownInputContext)
      const newXId = this.replaceSuffix(xId, this.ownInputContext)
      const newShowId = this.replaceSuffix(showId, this.ownInputContext)
      const newShowOptionId = this.replaceSuffix(showOptionId, this.ownInputContext)
      const newShowXId = this.replaceSuffix(showXId, this.ownInputContext)

      // new list element
      const newListElement = this.ownListElementTemplateTarget.content.cloneNode(true)
      newListElement.getElementById(mainId).id = newMainId
      newListElement.getElementById(tickId).id = newTickId
      newListElement.getElementById(optionId).id = newOptionId
      newListElement.getElementById(xId).id = newXId
      newListElement.querySelector('div').dataset.multiselectSearchIdParam = newMainId
      autocompleteContainer.prepend(newListElement)

      // new input element
      const newInputElement = this.ownInputElementTemplateTarget.content.cloneNode(true)
      newInputElement.getElementById(showId).id = newShowId
      newInputElement.getElementById(showOptionId).id = newShowOptionId
      newInputElement.getElementById(showXId).id = newShowXId
      newInputElement.getElementById(newShowXId).dataset.multiselectSearchIdParam = newMainId
      inputsContainer.prepend(newInputElement)
    }
    // const clone = this.ownListElementTemplateTarget.content.cloneNode(true)
    // console.log(clone)
  }

  createInitialOwnElement(autocompleteContainer, inputsContainer) {
    const initialListElement = this.ownListElementTemplateTarget.content.cloneNode(true)
    const initialInputElement = this.ownInputElementTemplateTarget.content.cloneNode(true)
    autocompleteContainer.prepend(initialListElement)
    inputsContainer.prepend(initialInputElement)
  }

  incrementId(id) {
    const numbers = id.match(/[0-9]+$/)
    const newNumbers = parseInt(numbers) + 1
    const newId = id.replace(numbers.toString(), newNumbers.toString())
    return newId
  }

  replaceSuffix(element, newSuffix) {
    const suffix = this.getSuffix(element)
    const newElement = element.replace(suffix, newSuffix)
    return newElement
  }

  // zaznaczenie opcji na liscie bez wybierania jej i wstawiania do inputu
  tickOption(prefix, id) {
    const tick = document.getElementById(prefix + '_tick_' + id)
    const x = document.getElementById(prefix + '_x_' + id)

    if (tick && tick.classList.contains('hidden')) {
      tick.classList.remove('hidden')
      x.classList.remove('hidden')
    }
  }

  // jeśli wciśnięto enter to dodaj to co w inputcie
  selectEnter(event) {
    if (event.key === 'Enter') {
      const params = {
        id: `${this.hiddenInputPrefix}_${this.ownInputContext}`
      }
      event.params = params
      this.select(event)
    }
  }

  readKeyboardInput(event) {
    const results = this.getAllVisibleListElements()

    if (this.listCounter <= -1) { this.listCounter = -1 }
    if (this.listCounter >= results.length) { this.listCounter = results.length - 1 }

    switch (event.key) {
      case 'ArrowDown':
        this.listCounter++
        break
      case 'ArrowUp':
        this.listCounter--
        // gdy dotrzemy do samej gory listy, wrzucamy focus na input
        if (this.listCounter <= 0) {
          this.userInputTarget.focus()
        }
        break
      case 'ArrowRight':
        if (document.activeElement !== this.userInputTarget) {
          this.userInputTarget.focus()
          this.userInputTarget.value = this.getValueFromListElement(results[this.listCounter].id)
          this.filterDropdown()
        }
        break
      default:
        break
    }
    if (results[this.listCounter]) {
      results[this.listCounter].focus()
    }
  }

  // sprawdzamy czy input jest aktywny, jesli nie to wybieramy element ktory mamy zaznaczony klawiatura na liscie
  keyboardSelect(event) {
    const activeElement = document.activeElement === this.userInputTarget
    if (event.key === 'Enter' && !activeElement) {
      event.params = { id: event.target.id }
      this.select(event)
    } else {
      this.selectEnter(event)
    }
  }

  // bierzemy tylko elementy listy ktore nie sa ukryte
  getAllVisibleListElements() {
    let results = this.ownListElementTargets.concat(this.listElementTargets)
    results = results.filter(element => !element.classList.contains('hidden'))
    return results
  }

  getValueFromListElement(id) {
    const targets = this.inputElementTargets.concat(this.ownInputElementTargets)
    const result = targets.filter(element => this.getSuffix(id) === this.getSuffix(element.id))
    return result[0].dataset.value
  }

  // blokujemy domyslne akcje klawiszy jesli aktywnym elementem jest ktorys z naszych targetow
  // zapobiega scrollowaniu okna np. gdy wybieramy cos strzalkami w multiselect
  preventKeyboardEvents(e) {
    const key = e.key
    if (this.dropdownOpen && (key === "ArrowDown" || key === "ArrowUp" || key === "Enter")) {
      e.preventDefault()
      console.log('prevent keys')
    }
  }

  unhideTickedIds() {
    const tickedIds = this.getInputTargetsValues()
    const elements = Array.from(this.element.querySelectorAll("div[data-value]"))
    const tickedElements = elements.filter(element => tickedIds.includes(element.dataset.value))
    if (this.hideOwn) {
      tickedElements.forEach(element => {
        element.classList.remove('hidden')
      })
    } else {
      this.unhideTickedIdsHideDuplicates(tickedElements)
    }
  }

  unhideTickedIdsHideDuplicates(tickedElements) {
    const originalTickedElements = tickedElements.filter(element => !element.id.includes('own'))
    const ownTickedElements = tickedElements.filter(element => element.id.includes('own'))
    console.log("%cticked original", "color: green", originalTickedElements)
    console.log("%cticked own", "color: green", ownTickedElements)

    const tickedElementsInOrder = originalTickedElements.concat(ownTickedElements)
    const uniqueElements = new Set()
    const spareElements = []
    const uniqueValues = new Set()

    tickedElementsInOrder.forEach(element => {
      if (!uniqueValues.has(element.dataset.value)) {
        uniqueElements.add(element)
        uniqueValues.add(element.dataset.value)
      } else {
        spareElements.push(element)
      }
    })

    spareElements.forEach(element => {
      element.classList.add('hidden')
      const suffix = this.getSuffix(element.id)
      const listElement = this.element.querySelector(`#${this.hiddenInputPrefix}_${suffix}`)
      listElement.classList.add('hidden')
    })
    uniqueElements.forEach(element => {
      element.classList.remove('hidden')
    })
  }

  clear() {
    const tickedIds = this.getInputTargetsValues()
    const elements = Array.from(this.element.querySelectorAll("div[data-value]"))
    const tickedElements = elements.filter(element => tickedIds.includes(element.dataset.value))
    console.log("tickedElements", tickedElements)

    for (const element of tickedElements) {
      const button = element.querySelector(`[data-action="click->multiselect-search#select"]`)
      const e = new Event("click")
      e.delete = true
      button.dispatchEvent(e)
    }
    this.userInputTarget.value = ""
  }
}
