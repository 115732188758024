export const locales = {
  pl: {
    dictDefaultMessage: "Dodaj załącznik lub wrzuć tutaj",
    dictFallbackMessage: "Twoja przeglądarka nie obsługuje funkcji przeciągania (drag&drop) do wgrywania plików",
    dictFallbackText: "Użyj zwykłej opcji dodawania plików",
    dictFileTooBig: "Plik jest za duży ({{filesize}}MiB). Maksymalny rozmiar: {{maxFilesize}}MiB.",
    dictInvalidFileType: "Nie możesz dodać załącznika tego typu.",
    dictResponseError: "Serwer odpowiedział ze statusem {{statusCode}}.",
    dictCancelUpload: "Anuluj",
    dictCancelUploadConfirmation: "Czy na pewno chcesz anulować dodawanie pliku?",
    dictRemoveFile: "Usuń plik",
    dictRemoveFileConfirmation: "Czy na pewno chcesz usunąć plik?",
    dictMaxFilesExceeded: "Limit dozwolonych załączników przekroczony",
    dictUploadCanceled: "Dodawanie pliku anulowane",
  },
  en: {},
}
