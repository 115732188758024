import { limitToRange } from '../lib/utils.js'
import { addMonths, addYears } from '../lib/date.js'

export function triggerDatepickerEvent(datepicker, type) {
  const detail = {
    date: datepicker.getDate(),
    viewDate: new Date(datepicker.picker.viewDate),
    viewId: datepicker.picker.currentView.id,
    datepicker,
  }
  datepicker.element.dispatchEvent(new CustomEvent(type, { detail }))
}

// direction: -1 (to previous), 1 (to next)
export function goToPrevOrNext(datepicker, direction) {
  const { minDate, maxDate } = datepicker.config
  const { currentView, viewDate } = datepicker.picker
  let newViewDate
  switch (currentView.id) {
    case 0:
      newViewDate = addMonths(viewDate, direction)
      break
    case 1:
      newViewDate = addYears(viewDate, direction)
      break
    default:
      newViewDate = addYears(viewDate, direction * currentView.navStep)
  }
  newViewDate = limitToRange(newViewDate, minDate, maxDate)
  datepicker.picker.changeFocus(newViewDate).render()
}

export function switchView(datepicker) {
  const viewId = datepicker.picker.currentView.id
  if (viewId === datepicker.config.maxView) {
    return
  }
  datepicker.picker.changeView(viewId + 1).render()
}

export function unfocus(datepicker) {
  if (datepicker.config.updateOnBlur) {
    datepicker.update({ autohide: true })
  } else {
    datepicker.refresh('input')
    datepicker.hide()
  }
}
