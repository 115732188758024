import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tax", "net", "gross"]

  connect() {
    console.log("deal tax connect")
    this.displayPrecision = 2
    this.precision = 7
  }

  calculateGross(net, tax, displayPrecision) {
    return (net * (100 + tax) / 100).toFixed(displayPrecision)
  }

  calculateNet(gross, tax, displayPrecision) {
    return (gross * 100 / (100 + tax)).toFixed(displayPrecision)
  }

  calculateTax(net, gross) {
    return parseInt(net === gross ? 0 : 100 * (gross - net) / gross)
  }

  taxInput(event) {
    const net = parseFloat(this.netTarget.value)
    const tax = parseFloat(this.taxTarget.value)
    this.grossTarget.value = this.calculateGross(net, tax, this.displayPrecision)
    this.netTarget.value = this.calculateNet(parseFloat(this.grossTarget.value), tax, this.displayPrecision)
  }

  netInput(event) {
    const tax = parseFloat(this.taxTarget.value)
    this.grossTarget.value = this.calculateGross(parseFloat(this.netTarget.value), tax, this.displayPrecision)
  }

  grossInput(event) {
    const tax = parseFloat(this.taxTarget.value)
    this.netTarget.value = this.calculateNet(parseFloat(this.grossTarget.value), tax, this.displayPrecision)
  }
}
