import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["moreInfo", "moreInfoButton", "tag"]
  static values = { loaded: Boolean }

  connect() {
    console.log('connected crm view')
    this.loadedValue = true
  }

  showMore() {
    console.log('show more')
    if (this.moreInfoTarget.classList.contains("hidden")) {
      this.moreInfoTarget.classList.remove("hidden")
      this.moreInfoButtonTarget.innerText = "Zwiń pełne dane"
    } else {
      this.moreInfoTarget.classList.add("hidden")
      this.moreInfoButtonTarget.innerText = "Rozwiń pełne dane"
    }
  }
}
