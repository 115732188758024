import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "tooltip"]

  connect() {
    if (!this.buttonTarget.dataset.action) this.buttonTarget.dataset.action = ""
    this.buttonTarget.dataset.action += " click->copy-to-clipboard#copy"
    // this.buttonTarget.dataset.action += " mouseover->copy-to-clipboard#showTooltip"
    this.buttonTarget.dataset.action += " mouseout->copy-to-clipboard#hideTooltip"

    this.buttonTarget.classList.add("cursor-pointer")
    this.initMessage = this.tooltipTarget.dataset.initMessage
  }

  copy(e) {
    const link = this.element.dataset.redirectUrl
    navigator.clipboard.writeText(link)

    this.showTooltip(e)
    this.tooltipTarget.innerText = this.tooltipTarget.dataset.copiedMessage
    setTimeout(() => {
      this.hideTooltip(e)
      this.tooltipTarget.innerText = this.initMessage
    }, 1500)
  }

  showTooltip(e) {
    this.tooltipTarget.classList.remove("hidden")
  }

  hideTooltip(e) {
    this.tooltipTarget.classList.add("hidden")
  }
}
