import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "field"]

  connect() {
    const fieldsMap = new Map()
    const fields = Array.from(this.fieldTargets)
    const selectOptions = Array.from(this.selectTarget.options)

    if (fields.length !== selectOptions.length) {
      console.error(`Fields have length ${fields.length} and select options have length ${selectOptions.length}`)
      return
    }

    for (let i = 0; i < fields.length; i++) {
      if (fieldsMap.has(selectOptions[i].value)) {
        console.error("Select options have duplicate values")
        return
      }
      fieldsMap.set(selectOptions[i].value, fields[i])
    }
    this.hideFieldsExceptSelected(fieldsMap)
    this.handleSelectChange(fieldsMap)
  }

  hideFieldsExceptSelected(fieldsMap) {
    const selectedValue = this.selectTarget.value
    fieldsMap.forEach((field, value) => {
      field.classList.toggle("hidden", value !== selectedValue)
    })
  }

  handleSelectChange(fieldsMap) {
    this.selectTarget.addEventListener("change", (event) => {
      this.hideFieldsExceptSelected(fieldsMap)
    })
  }
}
