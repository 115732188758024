import { Controller } from "@hotwired/stimulus"

/* przykład użycia: app/views/design/uix/stimulus.html.erb */

export default class extends Controller {
  static targets = ["theme", "darkbutton", "lightbutton", "defaultbutton"]

  connect() {
    // zapamiętywanie i odzyskiwanie scrolla na odpowiedniej wysokości
    // zasada działania: pamiętamy na raz max jeden typ danej strony (np.: index maili) [na podstawie request.path]
    // więc jeśli jesteśmy na inboxie i przejdziemy na zarchiwizowane maile, to scroll na inboxie jest zapomniany
    // za to jeśli z inboxu wejdziemy w pojedynczy email, a potem wrócimy to scroll zostanie wczytany poprawnie
    // WAŻNE: zapamiętujemy położenia w obrębie pojedynczej sesji, więc zamknięcie karty w przeglądarce resetuje zapamiętane scrolle
    addEventListener("turbo:before-render", function() {
      document.querySelectorAll("[data-remember-position]").forEach(function(element) {
        sessionStorage.setItem("scrollPosition_position_" + element.id, element.scrollTop)
        sessionStorage.setItem("scrollPosition_page_" + element.id, element.dataset.url)
      })
    })

    addEventListener("turbo:render", function() {
      document.querySelectorAll("[data-remember-position]").forEach(function(element) {
        if (sessionStorage.getItem("scrollPosition_page_" + element.id) === element.dataset.url) {
          element.scrollTop = sessionStorage.getItem("scrollPosition_position_" + element.id)
        }
      })
    })

    // Stara wersja zapamiętywania scrolla - chyba nie działała, lub przestała działać w pewnym momencie
    // ustawienie pozycji scrolla na odpowiedniej wysokości
    // if (!this.isMobileVariant) {
    //   var pathName = document.location.pathname
    //   window.onpagehide = function () {
    //     var scrollPosition = document.querySelector('main').scrollTop
    //     sessionStorage.setItem("scrollPosition_" + pathName, scrollPosition)
    //   }
    //   if (window.performance && window.performance.getEntriesByType("navigation")[0].type === "back_forward") {
    //     if (sessionStorage["scrollPosition_" + pathName]) {
    //       document.querySelector('main').scrollTop = sessionStorage.getItem("scrollPosition_" + pathName)
    //     }
    //   }
    // }

    // Change the icons inside the button based on previous settings
    this.setColor()
  }

  setColor() {
    this.defaultbuttonTarget.classList.add('text-gray-700')
    this.defaultbuttonTarget.classList.remove('text-blue-700')

    this.darkbuttonTarget.classList.add('text-gray-700')
    this.darkbuttonTarget.classList.remove('text-blue-700')

    this.lightbuttonTarget.classList.add('text-gray-700')
    this.lightbuttonTarget.classList.remove('text-blue-700')

    if (!('color-theme' in localStorage)) {
      this.defaultbuttonTarget.classList.remove('text-gray-700')
      this.defaultbuttonTarget.classList.add('text-blue-700')
    } else {
      if (localStorage.getItem('color-theme') === 'dark') {
        this.darkbuttonTarget.classList.remove('text-gray-700')
        this.darkbuttonTarget.classList.add('text-blue-700')
      } else {
        this.lightbuttonTarget.classList.remove('text-gray-700')
        this.lightbuttonTarget.classList.add('text-blue-700')
      }
    }
  }

  toggle(event) {
    const mode = event.params.mode

    if (mode === "default") {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.themeTarget.classList.add('dark')
      } else {
        this.themeTarget.classList.remove('dark')
      }
      localStorage.removeItem('color-theme')
    } else {
      if (mode === "dark") {
        this.themeTarget.classList.add('dark')
        localStorage.setItem('color-theme', 'dark')
      } else {
        this.themeTarget.classList.remove('dark')
        localStorage.setItem('color-theme', 'light')
      }
    }
    this.setColor()
  }

  get isMobileVariant() {
    return document.head.querySelector("meta[name=app_variant]").content === "mobile"
  }

  disconnect() {
  }
}
