import { Controller } from "@hotwired/stimulus"
import FieldHandler from "../helpers/field_handler"

export default class extends Controller {
  static targets = ["dropdown", "input", "bubblesContainer", "bubbleTemplate"]
  static values = { searchUrl: String, frameId: String, fieldName: String }

  searchTimeout = null
  connect() {
    this.frame = document.getElementById(this.frameIdValue)
    this.multiple = this.element.dataset.multiple === 'true'

    this.fieldHandler = new FieldHandler(this.element, this.fieldNameValue)

    // otwieranie dropdownu po kliknieciu w input
    this.inputTarget.addEventListener('click', this.showDropdown.bind(this))
    // zamykanie dropdownu po kliknieciu poza nim
    document.addEventListener('click', this.hideDropdown.bind(this))

    // akcje po zaladowaniu frame'a
    this.frame.addEventListener('turbo:frame-load', () => {
      this.toggleSelection()
      this.hideSpinner()
    })
  }

  search() {
    clearTimeout(this.searchTimeout)

    this.searchTimeout = setTimeout(() => {
      if (this.frame) {
        const fieldIds = Array.from(this.fieldHandler.fields).map(field => field.value).join(',')
        this.showDropdown()
        this.showSpinner()
        this.frame.src = `${this.searchUrlValue}?q=${encodeURIComponent(this.inputTarget.value)}&frame=${this.frameIdValue}&ids=${fieldIds}`
      } else {
        console.error(`Frame with ID ${this.frameIdValue} not found`)
      }
    }, 1000) // 1s
  }

  toggleResult(event) {
    event.preventDefault()
    const value = event.currentTarget.closest(`div[data-value]`).dataset.value
    const searchItem = this.frame.querySelector(`div[data-value="${value}"]`)
    console.log('Toggling:', searchItem, value)

    this.select(searchItem, value)
  }

  toggleSelection() {
    this.fieldHandler.fields.forEach(field => {
      const result = this.frame.querySelector(`div[data-value="${field.value}"]`)
      if (result) this.toggleSearchFieldDetails(result)
    })
  }

  toggleBubble(event) {
    // if this is a single select field, remove all other fields
    if (!this.multiple) {
      // mimick the click event on all bubbles to remove them
      const event = new Event('click')
      console.log('Removing all bubbles')
      this.bubblesContainerTarget.querySelectorAll('div[data-value]').forEach(bubble => {
        bubble.querySelector('button').dispatchEvent(event)
      })
    }

    const id = event.currentTarget.dataset.value
    const bubble = this.bubblesContainerTarget.querySelector(`div[data-value="${id}"]`)
    console.log('toggling bubble:', bubble, id)

    if (bubble) this.removeBubble(event)
    else this.addBubble(event)
  }

  addBubble(event) {
    event.preventDefault()
    const id = event.currentTarget.dataset.value
    const name = event.currentTarget.querySelector('#name').querySelector('span').textContent
    const bubble = this.bubbleTemplateTarget.content.cloneNode(true)

    bubble.querySelector('#bubble').dataset.value = id
    bubble.querySelector('#name').textContent = name
    this.bubblesContainerTarget.appendChild(bubble)
  }

  removeBubble(event) {
    event.preventDefault()
    console.log('Removing bubble')
    // zarowno we frame jak i w bubble istnieje div[data-value], maja one korespondujace ze soba wartosci
    const value = event.currentTarget.closest(`div[data-value]`).dataset.value
    const field = this.bubblesContainerTarget.querySelector(`div[data-value="${value}"]`)

    field.remove()
  }

  select(field, value) {
    console.log('Selecting:', field, value)
    this.fieldHandler.handleFieldChange(value)
    this.toggleSearchFieldDetails(field)
  }

  toggleSearchFieldDetails(field) {
    this.toggleCrossIcon(field)
    this.toggleTickIcon(field)
  }

  disconnect() {
    document.removeEventListener('click', this.hideDropdown.bind(this))
  }

  toggleTickIcon(target) { target.querySelector('.tick-icon').classList.toggle('hidden') }
  toggleCrossIcon(target) { target.querySelector('.cross-icon').classList.toggle('hidden') }
  hideSpinner() { this.element.querySelector('#spinner').classList.add('hidden') }
  showSpinner() { this.element.querySelector('#spinner').classList.remove('hidden') }
  showDropdown() { this.dropdownTarget.classList.remove('hidden') }
  hideDropdown(event) { if (!this.element.contains(event.target)) { this.dropdownTarget.classList.add('hidden') } }
}
