export default function scrollMainPage() {
  document.addEventListener('DOMContentLoaded', function() {
    const target = document.getElementsByTagName('main')[0]
    const sidebar = document.getElementById('sidebar')
    const navbar = document.getElementById('navbar')

    if (target && sidebar) {
      sidebar.addEventListener('wheel', function(event) {
        console.log(event.deltaY)
        target.scrollTo({ top: target.scrollTop + event.deltaY })
      })
    }
    if (target && navbar) {
      navbar.addEventListener('wheel', function(event) {
        if (event.target.closest(".no-wheel-navbar") == null) {
          console.log(event.deltaY)
          target.scrollTo({ top: target.scrollTop + event.deltaY })
        }
      })
    }
  })
}
