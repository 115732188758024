import { Controller } from "@hotwired/stimulus"
import Chart from "chart.js"

export default class extends Controller {
  static targets = ["canvas", "data"]

  connect() {
    const pieSprintChart = this.canvasTarget

    const labels = this.dataTargets.map(el => el.dataset.label)
    const backgroundColor = this.dataTargets.map(el => el.dataset.color)
    const count = this.dataTargets.map(el => el.dataset.count)

    const data = {
      labels: labels,
      datasets: [{
        data: count,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        hoverOffset: 2
      }]
    }

    this.chart = new Chart(pieSprintChart, {
      type: 'doughnut',
      data: data,
      options: {
        cutoutPercentage: 84,
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          display: false
        },
      },
    })
  }
}
