import { Controller } from "@hotwired/stimulus"

// Uogólniony kontroler do opcji masowych
// aby działał poprawnie wystarczy do checkboxów które mają być użyte w opcjach masowych dodać nazwę "ids[]"
// opcjonalne targety:
// counter - wyświetlamy informację o ilości obecnie zaznaczonych elementów (musi być postaci typu <div target="counter"> <div> ILOŚĆ </div> </div>)
// list - ograniczamy się do checkboxów będącymi podelementami listy
// select - używane do zaznaczania wszystkich checkboxów na raz

// Connects to data-controller="bulk-options-advanced"
export default class extends Controller {
  static targets = ["counter", "list", "select", "optionsDiv", "thead", "form"]
  static values = { loaded: Boolean }
  lastElement = null
  inputs = []

  connect() {
    this.inputs = this.list().querySelectorAll(`input[name="ids[]"]`)
    console.log('connect1 bulk options advanced')
    if (this.hasFormTarget) {
      this.formTargets.forEach(form => {
        form.setAttribute("data-action", "submit->bulk-options-advanced#submitForm")
      })
    }

    const selected = this.getSelectedIdsFromStorage()
    this.setInputs(selected)
    this.updateCounter()

    this.loadedValue = true
  }

  // ustawienie początkowe checkboxów, w tym dodanie akcji po kliknięciu
  setInputs(selected) {
    const selectedArray = selected.split(',')

    // ustawiamy data-action dla inputów o odpowienich id
    if (this.inputs) {
      this.inputs.forEach(element => {
        element.setAttribute("data-action", "click->bulk-options-advanced#checkOption")
        if (selectedArray.includes(element.value)) {
          element.checked = true
        }
      })
    }

    if (this.hasSelectTarget) {
      this.selectTarget.setAttribute("data-action", "click->bulk-options-advanced#selectAll")
    }
  }

  list() {
    if (this.hasListTarget) {
      return this.listTarget
    } else {
      return document
    }
  }

  // pobranie listy zaznaczonych checkboxów z sessionStorage
  getSelectedIdsFromStorage() {
    const selected = sessionStorage.getItem(`bulk_options_list`)
    if (sessionStorage.getItem(`bulk_options_page`) === window.location.href && selected) {
      return selected
    } else {
      this.saveSelectedIds('')
      return ''
    }
  }

  // zapisanie listy zaznaczonych checkboxów do sessionStorage
  saveSelectedIds(selection = this.getSelectedIds()) {
    sessionStorage.setItem(`bulk_options_page`, window.location.href)
    sessionStorage.setItem(`bulk_options_list`, selection)
  }

  // pobranie listy id obecnie zaznaczonych elementów (w formacie "1,2,3,4")
  getSelectedIds() {
    const list = this.list().querySelectorAll(`input[name="ids[]"]:checked`)

    let ids = ""

    list.forEach(element => {
      ids += `${element.value},`
    })

    // usuwamy zbędny przecinek z końca parametrów
    if (ids.charAt(ids.length - 1) === ',') {
      ids = ids.slice(0, -1)
    }

    return ids
  }

  // aktualizacja licznika zaznaczonych elementów na stronie
  updateCounter() {
    if (!this.hasCounterTarget) { return true }

    const currentValue = this.list().querySelectorAll(`input[name="ids[]"]:checked`).length

    this.counterTarget.children[0].innerHTML = this.list().querySelectorAll(`input[name="ids[]"]:checked`).length

    if (currentValue > 0) {
      this.counterTarget.classList.remove('invisible')
      this.optionsDivTarget.classList.remove('hidden')
      this.theadTarget.classList.add('top-12')
    } else {
      this.counterTarget.classList.add('invisible')
      this.optionsDivTarget.classList.add('hidden')
      this.theadTarget.classList.remove('top-12')
    }
  }

  // zaznaczenie checkboxa
  checkOption(event) {
    const input = event.target
    let newValue = input.checked
    let indexes = [-1, -1]
    const inputIndex = Array.prototype.indexOf.call(this.inputs, input)

    if (event.shiftKey && this.lastElement) {
      // jeśli kliknęliśmy z shiftem i nie był to pierwszy zaznaczony element to zaznaczamy zakres
      indexes[0] = Array.prototype.indexOf.call(this.inputs, document.querySelector(`input[value="${this.lastElement.value}"]`))
      indexes[1] = inputIndex

      indexes = indexes.sort((a, b) => { return a - b })
      newValue = this.lastElement.checked
    } else {
      // wpp zakresem jest pojedynczy checkbox
      indexes = [inputIndex, inputIndex]
    }

    Array.prototype.slice.call(this.inputs).slice(indexes[0], indexes[1] + 1).forEach(element => {
      element.checked = newValue
    })

    this.lastElement = { value: input.value, checked: input.checked }

    this.saveSelectedIds()
    this.updateCounter()
  }

  // zaznaczenie/odznaczenie wszystkich checkboxów
  selectAll() {
    if (this.selectTarget.checked) {
      this.inputs.forEach(element => {
        element.checked = true
      })
    } else {
      this.inputs.forEach(element => {
        element.checked = false
      })
    }

    this.lastElement = null
    this.saveSelectedIds()
    this.updateCounter()
  }

  submitForm(event) {
    const form = event.target

    form.action = event.params.link

    const idsInput = form.querySelector('input[name="ids"]')
    if (!idsInput) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'ids'
      input.value = this.getSelectedIds()
      form.appendChild(input)
    } else {
      idsInput.value = this.getSelectedIds
    }
  }
}
