import { Controller } from "@hotwired/stimulus"
import axios from 'axios'

export default class extends Controller {
  static targets = ["list", "field", "placeholderTemplate", "swapUrl", "changePageUrl"]

  connect() {
    this.setPriorities()
  }

  // Akcja po rozpoczęciu przeciągania elementu
  // chwilowo zmniejszamy opacity elementu na stronie
  dragstart(event) {
    if (event.target.getAttribute('data-drag-id')) {
      this.draggedItem = event.target

      event.dataTransfer.setData("application/drag-key", event.target.getAttribute("data-drag-id"))
      // event.dataTransfer.setData("draggedElement", event.target)

      event.target.classList.add('opacity-20')
      event.dataTransfer.effectAllowed = "move"
    } else {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  // Akcja na elemencie nad którym przenosimy inny w momencie rozpoczęcia przechodzenia nad nim
  dragenter(event) {
    const currentDropTarget = this.getDropTarget(event)
    if (currentDropTarget) {
      const oldDropTarget = this.currentDropTarget
      this.currentDropTarget = currentDropTarget

      if (oldDropTarget && oldDropTarget !== this.currentDropTarget) {
        oldDropTarget.classList.remove('border-2', 'border-dashed', 'box-border', 'border-blue')
      }

      if (this.draggedItem !== currentDropTarget) {
        currentDropTarget.classList.add('border-2', 'border-dashed', 'box-border', 'border-blue')
      }
    }
    event.preventDefault()
  }

  // Akcja na elemencie nad którym przenosimy inny w trakcie przechodzenia nad nim
  dragover(event) {
    event.preventDefault()
  }

  // Akcja upuszczenia elementu na stronie
  drop(event) {
    const draggedItem = this.getDragTarget(event)
    const dropTarget = this.getDropTarget(event)

    const insertAbove = this.swapFields(draggedItem, dropTarget)
    this.currentDropTarget = null

    event.preventDefault()

    const url = this.swapUrlTarget.value
    if (!url || draggedItem.dataset.id === dropTarget.dataset.id) return

    axios.post(url, {
      params: {
        dragged_id: draggedItem.dataset.id,
        drop_id: dropTarget.dataset.id,
        insert_above: insertAbove
      },
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) => {
        console.log('success')
      })
      .catch((error) => {
        console.log('update failed', error)
      })
  }

  // Przenosi na liście pola formularza (i z środkowej części i z prawej części)
  swapFields(draggedItem, dropTarget) {
    if (this.getPriority(draggedItem) < this.getPriority(dropTarget)) {
      dropTarget.insertAdjacentElement('afterend', draggedItem)

      return false
    } else {
      dropTarget.insertAdjacentElement('beforebegin', draggedItem)

      return true
    }
  }

  // Akcja po zakończeniu przenoszenia elementu
  // W tym miejscu ustawiamy nowy priorytet dla pól, na podstawie którego pola zachowują ustawioną kolejność
  dragend(event) {
    this.setPriorities()
  }

  setPriorities() {
    this.fieldTargets.forEach((field, index) => {
      field.dataset.priority = index + 1
      field.classList.remove('opacity-20')
    })
  }

  // Pomocnicza funkcja zwracająca obiekt nad którym przenosimy element
  getDropTarget(event) {
    if (!event.target.getAttribute('data-drag-id')) {
      return event.target.closest('[data-drag-id]')
    }
    return event.target
  }

  // Pomocnicza funkcja zwracająca obiekt który przenosimy
  getDragTarget(event) {
    const data = event.dataTransfer.getData("application/drag-key")
    return this.listTarget.querySelector(`[data-drag-id='${data}']`)
  }

  // Pomocnicza funkcja zwracająca obecny priorytet pola (używana do przenoszenia pól)
  getPriority(field) {
    if (!field) {
      return -1
    }

    return parseInt(field.dataset.priority)
  }

  moveUp(event) {
    const target = event.target.closest('[data-drag-id]')
    this.dropHelper(target, target.previousElementSibling)
    this.dragend(event)
  }

  moveDown(event) {
    const target = event.target.closest('[data-drag-id]')
    this.dropHelper(target, target.nextElementSibling)
    this.dragend(event)
  }

  changePageToPrev(event) {
    this.changePage(event, event.target.dataset.id, 'left')
  }

  changePageToNext(event) {
    this.changePage(event, event.target.dataset.id, 'right')
  }

  changePage(event, id, direction) {
    const url = this.changePageUrlTarget.value
    if (!url) return

    axios.get(url, {
      params: {
        changed_id: id,
        direction: direction
      },
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) => {
        console.log('success')
        window.location.reload()
      })
      .catch((error) => {
        console.log('update failed', error)
      })
  }

  // showPlaceholder(field) {
  //   console.log(field)

  //   const placeholder = document.createElement('td')
  //   const dragoverRow = field.querySelector('tr')

  //   console.log(dragoverRow)

  //   placeholder.rowSpan = dragoverRow.cells.length
  //   placeholder.innerHTML = this.placeholderTemplateTarget.innerHTML

  //   console.log(placeholder)

  //   this.dragoverColumns = Array.from(field.querySelectorAll('td')).map(function(element) {
  //                             return element.outerHTML
  //                           }).join('')
  //   dragoverRow.innerHTML = placeholder.outerHTML

  // }

  // hidePlaceholder(field) {
  //   console.log(this.dragoverColumns)
  //   const dragoverRow = field.querySelector('tr')
  //   dragoverRow.innerHTML = this.dragoverColumns
  // }
}
