// const attachmentsFormElementId = 'attachments_to_remove'
// const attachmentsClassName = 'intum_attachments'
export default function toggleDeleted (div, attachmentFormElementId) {
  div.toRemove = div.toRemove || false
  div.toRemove = !div.toRemove
  if (div.toRemove) {
    div.style = 'background-color:red;'
  } else {
    div.style = ''
  }
  var idsToRemove = []
  document.getElementsByClassName(div.className).forEach(div => {
    if (div.toRemove) idsToRemove.push(parseInt(div.id.split('_')[1]))
  })
  document.getElementById(attachmentFormElementId).value = idsToRemove
}
