export function stripTime(timeValue) {
  return new Date(timeValue).setHours(0, 0, 0, 0)
}

export function today() {
  return new Date().setHours(0, 0, 0, 0)
}

// Get the time value of the start of given date or year, month and day
export function dateValue(...args) {
  switch (args.length) {
    case 0:
      return today()
    case 1:
      return stripTime(args[0])
  }

  // use setFullYear() to keep 2-digit year from being mapped to 1900-1999
  const newDate = new Date(0)
  newDate.setFullYear(...args)
  return newDate.setHours(0, 0, 0, 0)
}

export function addDays(date, amount) {
  const newDate = new Date(date)
  return newDate.setDate(newDate.getDate() + amount)
}

export function addWeeks(date, amount) {
  return addDays(date, amount * 7)
}

export function addMonths(date, amount) {
  // If the day of the date is not in the new month, the last day of the new
  // month will be returned. e.g. Jan 31 + 1 month → Feb 28 (not Mar 03)
  const newDate = new Date(date)
  const monthsToSet = newDate.getMonth() + amount
  let expectedMonth = monthsToSet % 12
  if (expectedMonth < 0) {
    expectedMonth += 12
  }

  const time = newDate.setMonth(monthsToSet)
  return newDate.getMonth() !== expectedMonth ? newDate.setDate(0) : time
}

export function addYears(date, amount) {
  // If the date is Feb 29 and the new year is not a leap year, Feb 28 of the
  // new year will be returned.
  const newDate = new Date(date)
  const expectedMonth = newDate.getMonth()
  const time = newDate.setFullYear(newDate.getFullYear() + amount)
  return expectedMonth === 1 && newDate.getMonth() === 2 ? newDate.setDate(0) : time
}

// Calculate the distance bettwen 2 days of the week
function dayDiff(day, from) {
  return (day - from + 7) % 7
}

// Get the date of the specified day of the week of given base date
export function dayOfTheWeekOf(baseDate, dayOfWeek, weekStart = 0) {
  const baseDay = new Date(baseDate).getDay()
  return addDays(baseDate, dayDiff(dayOfWeek, weekStart) - dayDiff(baseDay, weekStart))
}

// Get the ISO week of a date
export function getWeek(date) {
  // start of ISO week is Monday
  const thuOfTheWeek = dayOfTheWeekOf(date, 4, 1)
  // 1st week == the week where the 4th of January is in
  const firstThu = dayOfTheWeekOf(new Date(thuOfTheWeek).setMonth(0, 4), 4, 1)
  return Math.round((thuOfTheWeek - firstThu) / 604800000) + 1
}

// Get the start year of the period of years that includes given date
// years: length of the year period
export function startOfYearPeriod(date, years) {
  /* @see https://en.wikipedia.org/wiki/Year_zero#ISO_8601 */
  const year = new Date(date).getFullYear()
  return Math.floor(year / years) * years
}
