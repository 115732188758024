// alert("intum js v1.0.2")

class IntumJs {
  toogle(targetId) {
    const target = document.getElementById(targetId)
    if (target.classList.contains('hidden')) {
      target.classList.remove('hidden')
    } else {
      target.classList.add('hidden')
    }
    return false
  }
}

export default new IntumJs()
